import { AxiosResponse } from 'axios';
import { config } from 'config/config';
import { NextPage } from 'next';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as api from 'services/api';
import apiPaths from 'services/apiPaths';
import { SetUserDataUpdate } from 'src/actions/authActions';
import { IRootReducers } from 'src/reducers';
import {
  PIN_INFILTRATED_COUNTRIES_Enum,
  PIN_PlatformID_Enum,
} from 'src/shared/enums';
import {
  IRegion,
  ISalesforceAddress,
  ISalesforceAddressRequest,
  IState,
  IUser,
} from 'src/shared/models';

import {
  useSize,
  YuButtonGroup,
  YuDropdown,
  YuFlex,
  YuFormik,
  YuInputText,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { ToastStatusEnum } from '../../shared/models/toast.model';
import { addToast } from '../../actions/toastActions';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yuFeedback } from '../../helpers/yuFeedback';
import {
  getAddressPostalCodeRulesByPlatform,
  getPlatformPostalCodeMaxLength,
  isESPlatform,
  patternValidation,
  PLATFORM_EQUAL_CP_LENGTH,
  ZIPCODE_LENGTH_parcial,
} from '../../../utils';
import { InfoModalContext } from '../common/InfoModal/InfoModalContext';

const exactLengthPostalCode = PLATFORM_EQUAL_CP_LENGTH.includes(
  config.APP.PLATFORM
);

interface OwnProps {
  userCountry?: string;
  addressSelected: ISalesforceAddress;
  closeAddress: () => void;
  onSaving?: (saving: boolean) => void;
  isNew: boolean;
  stateList: IState;
  onUpdate: (user: IUser) => (dispatch: Dispatch<SetUserDataUpdate>) => void;
  storedUserCountry: string;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

// TODO: Integrate with redux
const AddressSalesforceForm: NextPage<Props, unknown> = ({
  userCountry,
  addressSelected,
  closeAddress,
  isNew,
  onUpdate,
  storedUserCountry,
  addToast,
  onSaving = _.noop,
}): JSX.Element => {
  const [onReset, setOnReset] = useState<number>();
  const [saving, setSaving] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [lastCP, setLastCP] = useState<string>('');
  const { showInfoModal } = useContext(InfoModalContext);

  const { downXS } = useSize();

  const { control, handleSubmit, reset, formState, getValues, setValue } =
    useForm({
      defaultValues: addressSelected,
    });

  const stateList: IRegion[] = config.APP.PROVINCES[config.APP.PLATFORM].filter(
    ({ code }) => code !== null
  );

  // dont filter on unique-country platforms
  const states =
    stateList[0].countryCode && userCountry
      ? stateList.filter(({ countryCode }) => countryCode === userCountry)
      : stateList;

  const handleUpdateAddress = async () => {
    try {
      const data: ISalesforceAddressRequest = {
        targetAddress: getValues(),
      };

      const userData: AxiosResponse<IUser> = await api.putDataCall({
        dataPath: apiPaths.CALL.SALESFORCE_ADDRESS,
        data,
        callConfig: {},
      });

      addToast({
        status: ToastStatusEnum.SUCCESS,
        message: formatMessage({ id: 'form.address.update-success' }),
      });

      onUpdate(userData.data);
      closeAddress();
    } catch (error) {
      closeAddress();
      showInfoModal({
        title: formatMessage({ id: 'address.modal.save-error.title' }),
        description: formatMessage({
          id: 'address.modal.save-error.description',
        }),
        buttonText: formatMessage({ id: 'address.modal.save-error.button' }),
      });
    }
  };

  const handleCreateAddress = async () => {
    try {
      const data: ISalesforceAddressRequest = {
        targetAddress: getValues(),
      };

      const userData: AxiosResponse<IUser> = await api.postDataCall({
        dataPath: apiPaths.CALL.SALESFORCE_ADDRESS,
        data,
        callConfig: {},
      });

      addToast({
        status: ToastStatusEnum.SUCCESS,
        message: formatMessage({
          id: 'form.address.create-success',
        }),
      });

      onUpdate(userData.data);
      closeAddress();
    } catch (error) {
      closeAddress();
      showInfoModal({
        title: formatMessage({ id: 'address.modal.save-error.title' }),
        description: formatMessage({
          id: 'address.modal.save-error.description',
        }),
        buttonText: formatMessage({ id: 'address.modal.save-error.button' }),
      });
    }
  };

  useEffect(() => {
    reset(addressSelected);
    if (!isNew) {
      setOnReset(Date.now());
    }
  }, [JSON.stringify(addressSelected), isNew]);

  useEffect(() => {
    if (!isNew) {
      handleSubmit(_.noop)();
    }
  }, [onReset]);

  useEffect(() => {
    // Update saving on parent
    onSaving(saving);
  }, [saving]);

  const onSubmit = async () => {
    try {
      setSaving(true);

      if (isNew) {
        await handleCreateAddress();
      } else {
        await handleUpdateAddress();
      }
    } finally {
      setSaving(false);
    }
  };

  const onPostalCodeBlur = () => {
    let postalCode = getValues('addressCP') || '';

    if (
      ![
        PIN_PlatformID_Enum.PIN_CHILE,
        PIN_PlatformID_Enum.PIN_PORTUGAL,
      ].includes(config.APP.PLATFORM) &&
      storedUserCountry !== PIN_INFILTRATED_COUNTRIES_Enum.ANDORRA
    )
      return;

    setLastCP(postalCode);

    if (storedUserCountry === PIN_INFILTRATED_COUNTRIES_Enum.ANDORRA) {
      const AD_PRE = 'AD';
      const _postalCode = postalCode.match(/\d+/g)?.join('') ?? '';

      if (_postalCode.length === 3)
        postalCode = AD_PRE + _postalCode.slice(0, 3);
    }

    if (config.APP.PLATFORM === PIN_PlatformID_Enum.PIN_CHILE) {
      if (lastCP.length > postalCode.length) return;

      if (postalCode.length !== ZIPCODE_LENGTH_parcial) return;

      const ZEROS = '0000';
      postalCode = `${getValues('addressCP')?.slice(0, 3)}${ZEROS}`.slice(0, 7);
    }

    if (config.APP.PLATFORM === PIN_PlatformID_Enum.PIN_PORTUGAL) {
      const _postalCode = postalCode.match(/\d+/g)?.join('') ?? '';
      const length = _postalCode.length;

      if (length >= 5) {
        postalCode = `${_postalCode.slice(0, 4)}-${_postalCode.slice(4, 7)}`;
      }

      setLastCP(postalCode);
    }

    setValue('addressCP', postalCode, {
      shouldValidate: true,
    });
  };

  return (
    <form>
      <Controller
        control={control}
        name="addressName"
        rules={{
          required: formatMessage({
            id: `form.field.addressName-is-required`,
          }),
          pattern: {
            value: patternValidation.pattern,
            message: formatMessage({
              id: patternValidation.message,
            }),
          },
        }}
        render={({ fieldState, field: { onChange, value } }) => (
          <YuInputText
            name="addressName"
            className="fs-personal-data fs-mask"
            value={value}
            maxLength={100}
            disabled={saving}
            feedback={yuFeedback(fieldState.error?.message, 'error')}
            onChange={(e) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange(e.nativeEvent.target.value);
            }}
            label={formatMessage({ id: 'form.field.name' })}
          />
        )}
      />
      <YuSpacer size="M" />
      <Controller
        control={control}
        name="addressStreet"
        rules={{
          required: formatMessage({
            id: `form.field.addressStreet-is-required`,
          }),
          maxLength: {
            value: 30,
            message: formatMessage({ id: 'form.address.max-length' }),
          },
          pattern: {
            value: patternValidation.pattern,
            message: formatMessage({
              id: patternValidation.message,
            }),
          },
        }}
        render={({ fieldState, field: { onChange, value } }) => (
          <YuInputText
            name="addressStreet"
            className="fs-personal-data fs-mask"
            value={value}
            maxLength={30}
            disabled={saving}
            feedback={yuFeedback(fieldState.error?.message, 'error')}
            onChange={(e) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange(e.nativeEvent.target.value);
            }}
            hint={formatMessage({ id: 'form.address.street-hint' })}
            label={formatMessage({ id: 'form.field.street' })}
          />
        )}
      />
      <YuSpacer size="M" />
      <Controller
        control={control}
        name="addressNumber"
        rules={{
          required: formatMessage({
            id: `form.field.addressNumber-is-required`,
          }),
          maxLength: {
            value: 10,
            message: formatMessage({
              id: 'form.address.address-number.max-length',
            }),
          },
          pattern: {
            value: patternValidation.pattern,
            message: formatMessage({
              id: patternValidation.message,
            }),
          },
        }}
        render={({ fieldState, field: { onChange, value } }) => (
          <YuInputText
            name="addressNumber"
            className="fs-personal-data fs-mask"
            value={value}
            maxLength={10}
            disabled={saving}
            feedback={yuFeedback(fieldState.error?.message, 'error')}
            onChange={(e) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange(e.nativeEvent.target.value);
            }}
            hint={formatMessage({ id: 'form.address.number-hint' })}
            label={formatMessage({ id: 'form.field.number' })}
          />
        )}
      />
      <YuSpacer size="M" />
      <YuFlex
        flex="initial"
        flexDirection={downXS ? 'column' : 'row'}
        gap={downXS ? 'S' : 'XS'}
      >
        <YuFlex flexDirection="column" maxWidth={downXS ? '100%' : 132}>
          <Controller
            control={control}
            name="addressCP"
            rules={{
              required: formatMessage({
                id: `form.field.addressCP-is-required`,
              }),
              validate: (value) => {
                const [rule] = getAddressPostalCodeRulesByPlatform({
                  storedCountry: storedUserCountry,
                });
                if (rule && _.isFunction(rule.validator)) {
                  let response;
                  rule.validator(null, value, (result) => {
                    response = result;
                  });
                  return response;
                }
              },
            }}
            render={({ fieldState, field: { onChange, value } }) => (
              <YuInputText
                name="addressCP"
                className="fs-personal-data fs-mask"
                value={value}
                onBlur={onPostalCodeBlur}
                maxLength={getPlatformPostalCodeMaxLength({
                  storedCountry: storedUserCountry,
                })}
                minLength={
                  exactLengthPostalCode
                    ? getPlatformPostalCodeMaxLength({
                        storedCountry: storedUserCountry,
                      })
                    : 0
                }
                disabled={saving}
                feedback={yuFeedback(fieldState.error?.message, 'error')}
                onChange={(e) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onChange(e.nativeEvent.target.value.replace(/\D/g, ''));
                  onPostalCodeBlur();
                }}
                label={formatMessage({ id: 'form.field.zipCode' })}
              />
            )}
          />
        </YuFlex>
        <Controller
          control={control}
          name="addressCity"
          rules={{
            required: formatMessage({
              id: `form.field.addressCity-is-required`,
            }),
            pattern: {
              value: patternValidation.pattern,
              message: formatMessage({
                id: patternValidation.message,
              }),
            },
          }}
          render={({ fieldState, field: { onChange, value } }) => (
            <YuInputText
              name="addressCity"
              className="fs-personal-data fs-mask"
              value={value}
              maxLength={40}
              disabled={saving}
              feedback={yuFeedback(fieldState.error?.message, 'error')}
              onChange={(e) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange(e.nativeEvent.target.value);
              }}
              label={formatMessage({ id: 'form.field.city' })}
            />
          )}
        />
      </YuFlex>
      <YuSpacer size="M" />
      <YuFlex
        flex="initial"
        flexDirection={downXS ? 'column' : 'row'}
        gap={downXS ? 'S' : 'XS'}
      >
        <Controller
          control={control}
          name="addressState"
          rules={{
            required: formatMessage({
              id: `form.field.addressStateName-is-required`,
            }),
          }}
          render={({ fieldState, field: { value } }) => (
            <YuFormik
              onSubmit={_.noop}
              initialValues={{
                addressState: value?.toString() || '',
              }}
            >
              <YuDropdown
                pushContent
                className="fs-personal-data fs-mask"
                onChange={(e) => {
                  const region = _.find(stateList, { id: e[0]?.value });
                  if (region) {
                    setValue('region', region);
                    setValue('addressState', region.id, {
                      shouldValidate: true,
                    });
                    setValue('addressStateName', region.name);
                    setValue(
                      'country',
                      region?.country ?? config.REGISTER.COUNTRY
                    );
                    setValue(
                      'addressCountry',
                      region.countryCode ?? config.REGISTER.COUNTRY_CODE
                    );
                  }
                }}
                label={formatMessage({
                  id: 'form.address.state',
                })}
                disabled={saving}
                value={value}
                feedback={yuFeedback(fieldState.error?.message, 'error')}
                name="addressState"
                options={_.map(states, (value) => {
                  return {
                    label: value.name,
                    value: value.id,
                  };
                })}
              />
            </YuFormik>
          )}
        />
        <Controller
          control={control}
          name="addressCountry"
          rules={{
            required: formatMessage({
              id: `form.field.addressCountry-is-required`,
            }),
            pattern: {
              value: patternValidation.pattern,
              message: formatMessage({
                id: patternValidation.message,
              }),
            },
          }}
          render={({ fieldState, field: { onChange, value } }) => (
            <YuInputText
              disabled
              name="addressCountry"
              className="fs-personal-data fs-mask"
              value={value}
              maxLength={40}
              feedback={yuFeedback(fieldState.error?.message, 'error')}
              onChange={(e) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange(e.nativeEvent.target.value);
              }}
              label={formatMessage({
                id: isESPlatform ? 'form.address.country' : 'form.address.name',
              })}
            />
          )}
        />
      </YuFlex>
      <YuSpacer size="M" />
      <YuButtonGroup
        justify="end"
        buttons={[
          {
            onClick: handleSubmit(onSubmit),
            size: 'M',
            text: formatMessage({ id: 'button.save' }),
            variant: 'primary',
            prependLoading: saving,
            disabled: !formState.isDirty && !isNew,
          },
        ]}
      />
    </form>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    storedCountry: state.app.country ?? 'notLoged',
    storedUserCountry: state.auth.user?.salesforce?.userCountry ?? 'notLoged',
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ addToast }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressSalesforceForm);
