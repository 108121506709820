const apiPaths = {
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    REFRESH: '/auth/refresh-token',
    REGISTER_CHECK_EMAIL: '/auth/register/checkEmail',
    REGISTER_EMPLOYEE_V2: '/auth/register/employee/v2',
    REGISTER_MANAGER: '/auth/register/manager',
    REGISTER_MANAGER_V2: '/auth/register/manager/v2',
    REGISTER_PIN: '/auth/register/pin',
    REGISTER: '/auth/registerFo',
  },
  CALL: {
    CENTER_ACCEPT_REQUEST: '/userscenter/accept',
    CENTER_DECLINE_REQUEST: '/userscenter/decline',
    CENTER_REQUESTS: '/userscenter/list/requests',
    CENTER_USERS: '/center/usersList',
    CEMTER_MANAGERS: '/center/managerList',
    CHAT_CUSTOMER_CARE_CONFIGURATION:
      '/parameters/chatCustomerCare/configuration',
    COMPLETE_CHALLENGE_SURVEY: 'usersSurvey/challenge/complete',
    COMPLETE_SURVEY: 'usersSurvey/complete',
    CUSTOMER_CARE_MAIL: '/users/sendcustomercare',
    DELEGATE_MAIL: '/users/sendmaildelegate',
    EMBRACE_PRACTICE_BY_ID: '/center/embrace',
    EMBRACE_PRACTICES: '/center/embracecenters',
    LIWW_PRACTICES: '/center/liwwcenters',
    PARAMETERS: '/parameters',
    PUBLIC_HOME: '/contentpage/user/public-home',
    PUBLIC_MENU: '/usersmenu/publicMenu',
    PUBLIC_STATIC_PAGES: '/contentpage/static/public',
    SALESFORCE_ADDRESS: '/users/salesforce/address',
    SPECIALTY_LIST: '/specialty/combo/list',
    SURVEY: '/survey/user',
    USA_PRACTICES: '/center/allcenters',
    USER_HOME: '/contentpage/user/home',
    USER_INFO: '/users/user/info',
    USER_MENU: '/usersmenu/byUser',
    USER_STATIC_PAGES: '/contentpage/static',
    USER_ACTIVATE_REFERRAL_ROLE: '/users/user/activate-referral-role',
    USER_REFERRAL_DATA: '/users/user/referral',
    USER_REFERRAL_STATUS: '/user-role/referral/dashboard-visited',
    LIST_VALUES_BY_TYPE: '/list/type',
  },
  CHALLENGES: {
    // FIXME: DELETE categories, now use filters
    ATTEND_TO_EVENT: '/userResource/attend',
    CHALLENGE_VISIBILITY_FILES_NEW: '/upload/media-file',
    CHALLENGE_VISIBILITY_FILES: '/upload/image',
    COMPLETE_SHOPPING_CART: 'userResource/shopping',
    COMPLETE_TRAINING: '/userResource/training',
    COMPLETE_USER_RESOURCE: 'userResource/campaignResource',
    COMPLETE_EXTERNAL_REDEEM: '/userResource/external-redeem',
    EVENT_ICS: '/resource/calendar',
    GET_CHALLENGE_BY_ID: '/challenge',
    GET_ANNUAL_CAMPAIGN: '/challenge/annualcampaign',
    GET_CAMPAIGNS: '/challenge/campaigns/list',
    GET_CATEGORIES: '/challenge/categories',
    GET_CERTIFIED_COURSES: '/challenge/certifiedCourses/list',
    GET_EVENTS: '/challenge/events/list',
    GET_FILTERS: '/challenge/filters',
    GET_PEARLS: '/challenge/pearls/list',
    GET_QUIZ_STATISTICS: '/challenge/report/training',
    GET_RESTRICTED_CHALLENGES: '/challenge/restrictedChallenges',
    GET_TRAININGS: '/challenge/trainings/list',
    GET_USER_CHALLENGE_BY_SLUG: '/challenge/user/slug',
    LIKES: '/likes',
    SUBSCRIBE_USER_WAITING_LIST: '/userResource/waitingList',
    SUBSCRIBE_USER: '/userResource/subscribe/event',
    UNSUBSCRIBE_USER: '/userResource/unsubscribeFo',
    USER_RESOURCE_ARN_SURVEY: '/userResource/arnSurvey',
    USER_RESOURCE_PEARL: '/userResource/pearl',
    USER_RESOURCE_SELL_IN_REPEAT_ORDER: '/userResource/sellIn/reorder',
    USER_RESOURCE_SELL_IN: '/userResource/sellIn',
    USER_RESOURCE_SELL_OUT: '/userResource/sellOut',
    USER_RESOURCE_UPLOAD_MEDIA: '/userResource/uploadMedia',
    USER_RESOURCE: '/userResource',
    COMPLETE_RESOURCE_CLAUSE: '/userResource/completeClausePIN',
    START_CERTIFIED_COURSE: '/userResource/startCertifiedCourse', //TODO Este endpoint se debe crear en la api cuando se integre algún curso acreditado externo.
  },
  COMBO_IDS: {
    BRANDS: 'brands',
    // FIXME: DELETE categories, now use filters
    CATEGORIES: 'categoriesFilter',
    FILTERS: 'filters',
    FILTER_TYPE: 'filterType',
    CONTACT_CUSTOMER: 'contactCustomer',
    PREFIX_COUNTRIES: 'prefixCountries',
    PRODUCTS_LIMIT: 'productsLimitValues',
    // FIXME: DELETE pathologies, now use filters
    PATHOLOGIES: 'pathologies',
    SCIENTIFIC_MATERIAL_ARTICLE_TYPE: 'scientificMaterialArticleType',
    SCIENTIFIC_MATERIAL_TYPE: 'scientificMaterialType',
  },
  COMBOS: '/comboData',
  COMBOS_PUBLIC: '/comboData/public',
  CUSTOMER_REQUEST_ORDER: '/requestquotation',
  DISCLAIMERS: '/disclaimer/private',
  DISCLAIMERS_PUBLIC: '/disclaimer/public',
  COMMERCIAL_DISCLAIMERS: '/disclaimer/commercial',
  LABEL_REDEEM_PRODUCTS: '/labelRedeemProducts',
  MAGENTO: {
    COUNTRIES: '/magento/countries',
    GET_CART: '/magento/customer/cart',
    CART: '/magento/customer/cart',
    CART_ITEM: '/magento/customer/cart/item',
    GET_CUSTOMER_INVOICE_DETAILS: '/magento/customer/invoiceDetails',
    GET_CUSTOMER_INVOICE: '/magento/customer/invoices',
    GET_CUSTOMER_ORDER_DETAILS: '/magento/customer/orderDetails',
    GET_CUSTOMER_ORDERS: '/magento/customer/orders',
    GET_CUSTOMER_ORDER_BY_ID: '/magento/customer/orders/{{ORDER_ID}}',
    GET_ORDER_PRODUCT_BY_SKU: '/magento/products/ordered',
    CREATE_REORDER_DETAILS: '/magento/customer/reorderDetails',
    GET_CUSTOMER_POINTS_HISTORY:
      '/magento/customer/pointsHistory?page={{PAGE_NUMBER}}&size={{PAGE_SIZE}}',
    GET_CUSTOMER_POINTS: '/magento/customer/points',
    GET_CUSTOMER_REORDER: '/magento/customer/reorder',
    GET_CUSTOMER_REQUEST_ORDER: '/magento/products/quotationProducts',
    GET_ISDIN_BRANDS: '/magento/products/brands',
    ISDIN_ADDRESS: '/magento/customer/address',
    GET_SHOPPING_CART_PRODUCTS: '/magento/products/shoppingCart',
    PRODUCTS: '/magento/products/redeemproducts',
    REDEEM_CUPON: '/magento/coupon/points',
    DISCOUNT_CUPON: '/magento/coupon/discount',
    MAKE_ORDER: '/magento/customer/cart/order',
    ORDERED_PRODUCT_QTY_CURRENT_YEAR: '/magento/products/ordered',
  },
  MARKETING_MATERIALS: {
    ALL: '/marketingmaterials/materials/all',
  },
  SCIENTIFIC_MATERIALS: {
    ALL: '/scientificmaterials/materials/all',
    BYUSER: '/scientificmaterials/materials/byUser',
    DOWNLOADS: {
      ADDLOG: '/scientificmaterialsdownloadlogs',
    },
  },
  NEWSLETTER_SUBSCRIPTION: {
    COMMERCIAL: '/mailSubscription/commercialSubscription',
    COMMERCIAL_MODAL_CLOSED: '/newsletter-closed',
    COMMERCIAL_BY_MAIL:
      '/mailSubscription/commercialDoubleOptinubscriptionByMail',
    GET_COMMERCIAL_STATUS:
      '/mailSubscription/commercialSubscriptionTokenStatus',
  },

  PROFILE: {
    UPDATE_USER_INFO: '/users/fo',
    UPDATE_USER_EXTRA_FIELDS: '/users/fo/extra-fields',
    UPDATE_USER_PASSWORD: '/users/changepassword',
    UPDATE_USER_DOCUMENT_ID: '/users/fo/update-dni',
  },
  RECOVERY_PASSWORD: '/recoveryPassword/liww',
  RESET_PASSWORD: '/recoveryPassword/changepassword/liww',
  RESET_TOKEN_IS_VALID: '/recoveryPassword/changepassword/liww/is-valid',
  TRAINING_MATERIALS: 'marketingMaterials/training', // /:idChallenge
  UPLOAD_IMAGE: 'upload/image',
  SPECIALTY_AREAS: {
    LIST: '/specialtyArea',
  },
  TERM_AND_CONDITIONS: {
    FIND_BY_TYPE: '/termsAndConditions/{{TYPE}}',
    ACCEPT: '/termsAndConditions/{{TYPE}}/accept',
  },
};

export default apiPaths;
