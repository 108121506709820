import Router from 'next/router';
import { config } from 'config/config';
import { ParsedUrlQueryInput } from 'querystring';

const { BASE_URL } = config.APP;

const timeoutPromise = (timeout: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

const redirectTo = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destination: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { res, status }: any = {},
  query?: ParsedUrlQueryInput
) => {
  const newLocation = BASE_URL + destination;
  if (res) {
    res.writeHead(status || 302, {
      Location: newLocation,
    });
    res.end();
  } else {
    if (destination[0] === '/' && destination[1] !== '/') {
      await Router.push(
        { pathname: destination, query },
        { pathname: newLocation, query },
        {
          shallow: true,
        }
      );
    } else {
      window.location = newLocation;
    }
  }
  await timeoutPromise(100);
};

export default redirectTo;
