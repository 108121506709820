import routePaths from 'config/routes';
import { NextPage } from 'next';
import { Router } from 'next/router';
import { useEffect, useState } from 'react';
import SkeletonCampaign from '../skeletonComponents/Pages/Campaign.skeleton';
import SkeletonCampaignDetails from '../skeletonComponents/Pages/CampaignDetails.skeleton';
import SkeletonAnnualCampaign from '../skeletonComponents/Pages/AnnualCampaign.skeleton';
import { config } from 'config/config';
import SkeletonTraining from '../skeletonComponents/Pages/Training.skeleton';
import SkeletonTrainingDetails from '../skeletonComponents/Pages/TrainingDetails.skeleton';
import SkeletonTalkDetails from '../skeletonComponents/Pages/TalkDetails.skeleton';
import SkeletonTalks from '../skeletonComponents/Pages/Talks.skeleton';
import SkeletonMyProfile from '../skeletonComponents/Pages/MyProfile.skeleton';
import SkeletonMyAccount from '../skeletonComponents/Pages/MyAccount.skeleton';
import ScientificMaterials from '../skeletonComponents/Pages/ScientificMaterials.skeleton';
import SkeletonRedeemPoints from '../skeletonComponents/Pages/RedeemPoints.skeleton';
import SkeletonPdp from '../skeletonComponents/Pages/Pdp.skeleton';
import SkeletonMyAddresses from '../skeletonComponents/Pages/MyAddresses.skeleton';
import SkeletonMyOrders from '../skeletonComponents/Pages/MyOrders.skeleton';
import SkeletonMyPoints from '../skeletonComponents/Pages/MyPoints.skeleton';
import SkeletonMyOrdersDetails from '../skeletonComponents/Pages/MyOrdersDetails.skeleton';
import SkeletonCenterMembers from '../skeletonComponents/Pages/CenterMembers.skeleton';
import SkeletonCenterProfile from '../skeletonComponents/Pages/CenterProfiles.skeleton';
import SkeletonMarketingMaterials from '../skeletonComponents/Pages/MarketingMaterials.skeleton';
import SkeletonMyBag from '../skeletonComponents/Pages/MyBag.skeleton';
import SkeletonCheckout from '../skeletonComponents/Pages/Checkout.skeleton';

const SkeletonLayout: NextPage<unknown, unknown> = (props) => {
  const { children } = props;

  const {
    ANNUAL_CAMPAIGN,
    CAMPAIGN,
    CAMPAIGN_DETAIL,
    TRAININGS,
    TRAINING_DETAIL,
    TALKS,
    TALK_DETAIL,
    CONGRESS_DETAIL,
    MY_ACCOUNT,
    SCIENTIFIC_MATERIALS,
    MARKETING_MATERIALS,
    REDEEM_POINTS,
    MY_CENTER,
  } = routePaths.PAGES;

  const [routeInLoad, setrouteInLoad] = useState<string | null>(null);

  useEffect(() => {
    const start = (_route: string) => {
      const route = _route.replace(config.APP.BASE_URL, '');

      scrollTo(0, 0);
      setrouteInLoad(route);
    };
    const end = () => {
      setrouteInLoad(null);
    };
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);
    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, []);

  const renderSkeleton = () => {
    const routesToSkeleon = {
      [ANNUAL_CAMPAIGN]: <SkeletonAnnualCampaign />,
      [CAMPAIGN]: <SkeletonCampaign />,
      [CAMPAIGN_DETAIL]: <SkeletonCampaignDetails />,
      [TRAININGS]: <SkeletonTraining />,
      [TRAINING_DETAIL]: <SkeletonTrainingDetails />,
      [REDEEM_POINTS.MY_BAG]: <SkeletonMyBag />,
      [REDEEM_POINTS.CHECKOUT]: <SkeletonCheckout />,
      [TALKS]: <SkeletonTalks />,
      [TALK_DETAIL]: <SkeletonTalkDetails />,
      [CONGRESS_DETAIL]: <SkeletonTalkDetails />,
      [SCIENTIFIC_MATERIALS]: <ScientificMaterials />,
      [MARKETING_MATERIALS]: <SkeletonMarketingMaterials />,
      [REDEEM_POINTS.MAIN]: <SkeletonRedeemPoints />,
      [REDEEM_POINTS.DETAILS]: <SkeletonPdp />,
      [MY_ACCOUNT.MY_PROFILE]: <SkeletonMyProfile />,
      [MY_ACCOUNT.MY_ADDRESSES]: <SkeletonMyAddresses />,
      [MY_ACCOUNT.ACCOUNT_INDEX]: <SkeletonMyAccount />,
      [MY_ACCOUNT.ORDERS]: <SkeletonMyOrders />,
      [MY_ACCOUNT.ORDER_DETAILS]: <SkeletonMyOrdersDetails />,
      [MY_ACCOUNT.MY_POINTS]: <SkeletonMyPoints />,
      [MY_CENTER.CENTER_MEMBERS]: <SkeletonCenterMembers />,
      [MY_CENTER.CENTER_PROFILE]: <SkeletonCenterProfile />,
    };

    const directRoutes = [
      routePaths.INDEX,
      ANNUAL_CAMPAIGN,
      CAMPAIGN,
      TRAININGS,
      TALKS,
      SCIENTIFIC_MATERIALS,
      MARKETING_MATERIALS,
      MY_ACCOUNT.MY_PROFILE,
      MY_ACCOUNT.ORDERS,
      MY_ACCOUNT.MY_ADDRESSES,
      MY_ACCOUNT.ACCOUNT_INDEX,
      MY_ACCOUNT.MY_POINTS,
      REDEEM_POINTS.MAIN,
      MY_CENTER.CENTER_MEMBERS,
      MY_CENTER.CENTER_PROFILE,
      REDEEM_POINTS.MY_BAG,
      REDEEM_POINTS.CHECKOUT,
    ];

    const detailRoutes = {
      CAMPAIGN_DETAIL: CAMPAIGN_DETAIL,
      TRAINING_DETAIL: TRAINING_DETAIL,
      TALK_DETAIL: TALK_DETAIL,
      CONGRESS_DETAIL: CONGRESS_DETAIL,
      REDEEM_POINTS_DETAIL: REDEEM_POINTS.DETAILS,
      MY_ORDERS_DETAIL: MY_ACCOUNT.ORDER_DETAILS,
    };

    const getRouteDetail = (routeInLoad) => {
      for (const key in detailRoutes) {
        const baseRoute = detailRoutes[key].replace('[id]', '');
        if (routeInLoad.startsWith(baseRoute)) {
          return detailRoutes[key];
        }
      }
      return null;
    };

    const routeDetail = getRouteDetail(routeInLoad);

    const targetRoute = (() => {
      if (directRoutes.includes(routeInLoad)) {
        return routeInLoad;
      } else if (routeDetail) {
        return routeDetail;
      }
      return null;
    })();

    return (
      <div className="skeleton-layout" aria-hidden>
        {routesToSkeleon[targetRoute]}
      </div>
    );
  };

  return <>{!routeInLoad ? children : renderSkeleton()}</>;
};

export default SkeletonLayout;
